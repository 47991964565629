// Here you can add other styles
.overly-loader{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 999999999999;
    cursor: pointer;
  }
  .hide{
      display:none;
  }
  .show{
      display:block;
  }

  .overly-loader .loader-img{
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 50px;
      color: white;
      transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
  }
  .overly-loader .loader-img p{
      font-size: 10px;
  }
  .overly-loader .loader-img img{
      width: 30px;
  }
  .send_right{
      float:right;
  }

  .privilege-pad{
      margin-bottom:10px;
  }
  .verify-log-note{
      font-size: 10px;
  }
  .loginBody-class{
     // background-color: rgba(0,0,0,1);
  }
  .login-header{
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    background-color: #fff;
    justify-content: center;
    -webkit-box-shadow: 0 8px 6px -6px rgb(233, 171, 36);
	-moz-box-shadow: 0 8px 6px -6px rgb(233, 171, 36);
	 box-shadow: 0 8px 6px -6px rgb(233, 171, 36);
    z-index: 999;
  }
  .login-header img{
    width: 40px;
    padding: 3px;
    margin-left: 10px;
    float: left;
    justify-content: center;
  }
  .login-header h3{
    float: left;
    padding: 5px;
    margin-top: 7px;
    justify-content: center;
  }
  .login-header .uniName{
    float: right;
    padding: 5px;
    margin-top: 7px;
    margin-right: 10px;
    justify-content: center;
  }

  .login-bottom{
    position: fixed;
    bottom:0px;
    left:0px;
    height: 50px;
    width: 100%;
    background-color: #fff;
    justify-content: center;
    -webkit-box-shadow:  0px -4px 3px rgb(233, 171, 36);
	-moz-box-shadow:  0px -4px 3px rgb(233, 171, 36);
	 box-shadow: 0px -4px 3px rgb(233, 171, 36);
     z-index: 998;

  }
  .login-bottom p{
      margin-top: 10px;;

  }
  .login-window{
      overflow-y: auto;
  }
  .logo-dashboard{
      float: left;
      width: 40px;
  }
  .c-avatar-img-my{
      border-radius: 0;
  }
